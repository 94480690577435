import styled from 'styled-components';
import { colors } from '../../style/styles';

export const Container = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100vh;
  justify-content: flex-end;
  z-index: 2;
`;

export const SubContainer = styled.div`
  display: flex;
  position: fixed;
  margin: 0px;
  width: 40%;
  height: 100vh;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const TitleContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
`;

export const Title = styled.span`
  color: #636363;
  width: 100%;
  font-weight: 580;
  font-size: 24px;
  text-align: left;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 50px;
  margin-bottom: 3%;
  margin-right: 5%;
  right: 50px;
`;

export const ButtonsContainerCancel = styled.div`
  margin-right: 2%;
  span {
    color: ${colors.blue};
  }
  button {
    padding: 15px 15px;
    border-radius: 10px;
    border: solid 1px ${colors.blue};
    background-color: #ffffff !important;
    cursor: pointer;
  }
`;

export const ButtonsContainerContinue = styled.div`
  span {
    color: #ffffff;
  }
  button {
    padding: 15px 15px;
    border-radius: 10px;
    background-color: ${colors.blue} !important;
    cursor: pointer;
  }
  button:disabled {
    background-color: #c0c0c0 !important;
    cursor: default;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 20px);
  height: auto;
  padding: 10px;
  margin: 15px 5px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 14px);
  padding: 7px;

  textarea {
    width: calc(75% - 20px);
    height: 200px;
    border: 1px solid #636363;
    border-radius: 6px;
    margin: 0px 5px;
  }
  input {
    width: 100% !important;
    border: 1px solid #636363;
  }

  select {
    width: 100% !important;
    border: 1px solid #0082c1;
  }
`;

export const Text = styled.span`
  display: flex;
  text-align: left;
  font-size: 12px !important;
  align-items: center;
  color: #636363;
  margin-left: 15px;
`;

export const ContainerComplement = styled.div`
  display: flex;
  position: fixed;
  margin: 0px;
  width: 40%;
  height: 80vh;
  overflow: hidden;
  overflow-y: auto;
`;

export const SubContainerComplement = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const ContainerButtonIncrement = styled.div`
  display: flex;
  position: absolute;
  margin: 0% 3%;
  span {
    color: ${colors.blue} !important;
  }
  button {
    color: ${colors.blue} !important;
    padding: 15px 15px;
    border-radius: 10px;
    border: none;
    background-color: rgb(252, 235, 234) !important;
    cursor: pointer;
  }
`;

export const TabsContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0px;
`;
