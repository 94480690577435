import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  body {
    color: #000000;
    background-color: #f0f0fa;
    font-family: 'Roboto', sans-serif;
  }

  #root {
    height: 100%;
  }`;
