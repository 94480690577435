/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { Cancel } from '@material-ui/icons';

import Button from '../Button';
import CardOrderManager from '../CardOrderManager';
import ModalCancel from '../ModalCancel';
import api from '../../service/api';
// import { IOrderDTO } from '../../interfaces/IOrderDTO';
import IconButton from '../IconButton';
import {
  Modal,
  Container,
  SubContainer,
  TitleContainer,
  Title,
  OrderContainer,
  MessageContainer,
  ButtonsContainer,
  ButtonCancelContainer,
  ButtonConfirmContainer,
  CardContainer,
} from './styles';

interface IRouteParams {
  id: string;
  onClose: any;
}

const OrderManegerComponent = ({ id, onClose }: IRouteParams) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [orders, setOrders] = useState();
  const [snakOpen, setSnackOpen] = useState<boolean>();
  const [token] = useState(localStorage.getItem('token'));
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

  useEffect(() => {
    api.get(`orders/${id}`, config).then((response) => {
      setOrders(response.data);
    });
  }, []);

  const updateOrders = async () => {
    await api.get(`orders/${id}`, config).then((response) => {
      setOrders(response.data);
      setModalVisible(false);
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 300);
    });
  };

  async function handleSubmit(statusP: string) {
    await api
      .put(
        `/orders/${orders?.id}`,
        {
          storeId: orders?.storeId,
          status: statusP,
        },
        config,
      )
      .then((res) => {
        if (res.data.success === true) {
          setSnackOpen(true);
          window.location.reload();
        }
      });
  }

  return (
    <Modal>
      {orders?.status === 'RECEIVED' ? (
        <Container>
          <SubContainer>
            <TitleContainer>
              <Title>Gerenciador de Pedidos</Title>
              <IconButton icon={<Cancel />} onClick={onClose} />
            </TitleContainer>
            <OrderContainer>
              <CardContainer>
                <CardOrderManager orderId={orders ? orders.id : ''} />
              </CardContainer>
            </OrderContainer>
            <MessageContainer>
              <span>Verifique se há possibilidade de assumir o pedido.</span>
              <span>Não esqueça de verificar se há adicionais.</span>
              <ButtonsContainer>
                <ButtonCancelContainer>
                  <Button onClick={() => setModalVisible(true)} title="CANCELAR" />
                </ButtonCancelContainer>
                <ButtonConfirmContainer>
                  <Button onClick={() => handleSubmit('APPROVED')} title="CONFIRMAR" />
                </ButtonConfirmContainer>
              </ButtonsContainer>
            </MessageContainer>
          </SubContainer>
          {modalVisible ? (
            <ModalCancel
              id={orders?.id}
              storesP={orders?.storeId}
              onClose={() => {
                updateOrders();
              }}
            />
          ) : null}
        </Container>
      ) : null}
      {orders?.status === 'APPROVED' ? (
        <Container>
          <SubContainer>
            <TitleContainer>
              <Title>Gerenciador de Pedidos</Title>
              <IconButton icon={<Cancel />} onClick={onClose} />
            </TitleContainer>
            <OrderContainer>
              <CardContainer>
                <CardOrderManager orderId={orders ? orders.id : ''} />
              </CardContainer>
            </OrderContainer>
            <MessageContainer>
              <span>EM PRODUÇÃO.</span>
              <span>O pedido {orders.shortReference} está pronto para retirada?</span>
              <ButtonsContainer>
                <ButtonConfirmContainer>
                  <Button onClick={() => handleSubmit('TRAFFIC')} title="CONFIRMAR" />
                </ButtonConfirmContainer>
              </ButtonsContainer>
            </MessageContainer>
          </SubContainer>
        </Container>
      ) : null}
      {orders?.status === 'TRAFFIC' ? (
        <Container>
          <SubContainer>
            <TitleContainer>
              <Title>Gerenciador de Pedidos</Title>
              <IconButton icon={<Cancel />} onClick={onClose} />
            </TitleContainer>
            <OrderContainer>
              <CardContainer>
                <CardOrderManager orderId={orders ? orders.id : ''} />
              </CardContainer>
            </OrderContainer>
            <MessageContainer>
              <span>PRONTO PARA RETIRADA.</span>
              <span>O pedido{orders.shortReference} foi entregue?</span>
              <ButtonsContainer>
                <ButtonConfirmContainer>
                  <Button onClick={() => handleSubmit('CONCLUDED')} title="CONFIRMAR" />
                </ButtonConfirmContainer>
              </ButtonsContainer>
            </MessageContainer>
          </SubContainer>
        </Container>
      ) : null}
      {orders?.status === 'CONCLUDED' ? (
        <Container>
          <SubContainer>
            <TitleContainer>
              <Title>Gerenciador de Pedidos</Title>
              <IconButton icon={<Cancel />} onClick={onClose} />
            </TitleContainer>
            <OrderContainer>
              <CardContainer>
                <CardOrderManager orderId={orders ? orders.id : ''} />
              </CardContainer>
            </OrderContainer>
            <MessageContainer>
              <span>PEDIDO ENTREGUE.</span>
              <ButtonsContainer>
                <ButtonConfirmContainer>
                  <Button onClick={onClose} title="FINALIZAR" />
                </ButtonConfirmContainer>
              </ButtonsContainer>
            </MessageContainer>
          </SubContainer>
        </Container>
      ) : null}
      {orders?.status === 'CANCELED' ? (
        <Container>
          <SubContainer>
            <TitleContainer>
              <Title>Gerenciador de Pedidos</Title>
              <IconButton icon={<Cancel />} onClick={onClose} />
            </TitleContainer>
            <OrderContainer>
              <CardContainer>
                <CardOrderManager orderId={orders ? orders.id : ''} />
              </CardContainer>
            </OrderContainer>
            <MessageContainer>
              <span>PEDIDO CANCELADO.</span>
              <span>{orders.comments}</span>
              <ButtonsContainer>
                <ButtonConfirmContainer>
                  <Button onClick={onClose} title="VOLTAR" />
                </ButtonConfirmContainer>
              </ButtonsContainer>
            </MessageContainer>
          </SubContainer>
        </Container>
      ) : null}
      {snakOpen ? (
        <Snackbar open={snakOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            Pedido Atualizado com Sucesso.
          </Alert>
        </Snackbar>
      ) : null}
    </Modal>
  );
};

export default OrderManegerComponent;
