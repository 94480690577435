/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
import { createStore } from 'redux';

const INITIAL_STATE = {
  typecast: '',
  category: 'P',
  name: '',
  description: '',
  group: '',
  price: '',
  maxQuantity: '',
  minQuantity: '0',
  ordenationProduct: '',
  complementName: '',

  incrementData: [],
  complementData: [],

  deleteComplements: [],
  deleteIncrements: [],
};

let complements: any = [];
let deleteComplement: any = [];
let deleteIncrement: any = [];

const product = (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'ADD_INCREMENT':
      return { ...state, incrementData: action.increment };

    case 'SAVE_COMPLEMENT':
      action.complementSave.forEach((complement: any) => complements.push(complement));
      state.complementData = [...complements];
      return { ...state, complementData: state.complementData };

    case 'REMOVE_COMPLEMENT':
      complements = [...action.complement];
      return { ...state, complementData: [...complements] };

    case 'DELETE_COMPLEMENT':
      deleteComplement.push(action.complementChange);
      state.deleteComplements = [...deleteComplement];
      return { ...state, deleteComplements: state.deleteComplements };

    case 'DELETE_INCREMENT':
      deleteIncrement.push(action.removeIncrement[0]);
      state.deleteIncrements = [...deleteIncrement];
      return { ...state, deleteIncrements: state.deleteIncrements };

    case 'COMPLEMENT_NAME':
      return { ...state, complementName: action.complementName };

    case 'CLEAR_INITIAL_STATE':
      state.price = '';
      state.typecast = '';
      state.category = '';
      state.name = '';
      state.description = '';
      state.group = '';
      state.price = '';
      state.maxQuantity = '';
      state.minQuantity = '';
      state.ordenationProduct = '';
      state.complementName = '';
      return { ...state, complementName: action.complementName };

    case 'CLEAR_INCREMENT':
      state.incrementData = [];
      return { ...state, incrementData: state.incrementData };

    case 'CLEAR_COMPLEMENT':
      complements = [];
      state.complementData = [];
      return { ...state, complementData: state.complementData };

    case 'CATEGORY':
      return { ...state, category: action.category };

    case 'PRODUCT_NAME':
      return { ...state, name: action.name };

    case 'GROUP_SELECTED':
      return { ...state, group: action.group };

    case 'DESCRIPTION':
      return { ...state, description: action.description };

    case 'PRICE':
      return { ...state, price: action.price };

    default:
      return state;
  }
};

const store = createStore(product);

export { store };
