import React from 'react';
import { Lens } from '@material-ui/icons';
import {
  Container,
  ApprovedContainer,
  ConcludedContainer,
  ReceivedContainer,
  TrafficContainer,
  CanceledContainer,
  Text,
} from './styles';

const Caption = () => (
  <Container>
    <ReceivedContainer>
      <Lens />
      <Text>Pendente de Confirmação</Text>
    </ReceivedContainer>
    <ApprovedContainer>
      <Lens />
      <Text>Em produção</Text>
    </ApprovedContainer>
    <TrafficContainer>
      <Lens />
      <Text>Pronto para retirada</Text>
    </TrafficContainer>
    <ConcludedContainer>
      <Lens />
      <Text>Entregue</Text>
    </ConcludedContainer>
    <CanceledContainer>
      <Lens />
      <Text>Cancelado</Text>
    </CanceledContainer>
  </Container>
);

export default Caption;
