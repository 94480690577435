/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable padded-blocks */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */

import React, { useRef, useEffect, useState } from 'react';
import { Card, Grid, Typography, IconButton, Modal, Box, TextField, InputAdornment, Button } from '@material-ui/core';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import TwoWheelerIcon from '@material-ui/icons/TwoWheeler';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// @ts-ignore
import mapboxgl from '!mapbox-gl';
import api from '../../service/api';
import cash from '../../util/cash';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

export default function MapDrawer() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-34.8406);
  const [lat, setLat] = useState(-7.9938);
  const [zoom, setZoom] = useState(19);
  const [open, setOpen] = useState(false);
  const [zoneToDelete, setZoneToDelete] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [unityId, setUnityId] = useState('');
  const [polygonToDB, setPolygonToDB] = useState({});
  const [loadedData, setLoadedData] = useState([]);
  const [reload, setReload] = useState(false);
  const zoneNameRef = useRef('');
  const zonePriceRef = useRef('');

  const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const Draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: true,
    },
    defaultMode: 'simple_select',
  });

  // @ts-ignore
  const [authStore] = useState(JSON.parse(localStorage.getItem('authStore')));
  const [token] = useState(localStorage.getItem('token'));

  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',

      zoom,
      navigator,
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    // @ts-ignore
    map.current.addControl(new mapboxgl.NavigationControl());
    if (!alreadyLoaded) {
      // @ts-ignore
      map.current.addControl(Draw);
      setAlreadyLoaded(!alreadyLoaded);
    }
    // @ts-ignore
    map.current.on('load', () => {
      // @ts-ignore
      const requestData = { storeName: authStore.store_name };
      api.post('/address/select-areas', requestData).then((res) => {
        const { data } = res.data;
        data?.forEach((item: any) => {
          // @ts-ignore
          Draw.add(item.data);
        });
      });

      api.get('/address/unity', config).then((res) => {
        const { data } = res.data;

        setUnityId(data.id);
        setLat(data.latitude);
        setLng(data.longitude);
        // @ts-ignore
        map.current.flyTo({
          center: [data.longitude, data.latitude],
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
        // @ts-ignore
        new mapboxgl.Marker().setLngLat([data.longitude, data.latitude]).addTo(map.current);
      });
    });

    // @ts-ignore
    map.current.on('draw.create', getCreated);
    // @ts-ignore
    map.current.on('draw.delete', getDeleted);
    // @ts-ignore
    map.current.on('move', () => {
      // @ts-ignore
      setLng(map.current.getCenter().lng.toFixed(4));
      // @ts-ignore
      setLat(map.current.getCenter().lat.toFixed(4));
      // @ts-ignore
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleSubmit = () => {
    // @ts-ignore
    polygonToDB[0].unityId = unityId;
    // @ts-ignore
    polygonToDB[0].zoneName = zoneNameRef.current.value;
    // @ts-ignore
    polygonToDB[0].zonePrice = zonePriceRef.current.value;

    // @ts-ignore
    const data = polygonToDB[0];
    api.post('/address/delivery-area', data, config).then((res) => {
      if (res.data.success) {
        setOpen(false);
        setReload(true);
      }
    });
  };

  const handleDeleteArea = async (id: string, name: string, price: string) => {
    setZoneToDelete({ id, zoneName: name, zonePrice: price });
    setOpenDelete(true);
  };
  const handleConfirmDeleteArea = async () => {
    // @ts-ignore
    await api.post('/address/del-delivery-area-bt', { id: zoneToDelete.id }, config);
    setReload(true);
    setOpenDelete(!openDelete);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  useEffect(() => {
    const requestData = { storeName: authStore.store_name };
    api.post('/address/select-areas', requestData).then((res) => {
      const { data } = res.data;
      setLoadedData(data);
      console.log(loadedData);
      setReload(false);
    });
  }, [reload]);

  const getCreated = async (e: any) => {
    setPolygonToDB(e.features);
    handleOpen();
  };

  const getDeleted = (e: any) => {
    const data = e.features;
    api.post('/address/del-delivery-area', data, config);
    setReload(true);
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <div
        style={{
          backgroundColor: '#0198DA',
          color: '#fff',
          padding: '5px 5px',
          fontFamily: 'monospace',
          zIndex: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          margin: '12px',
          marginLeft: '22vw',
          borderRadius: '10px',
        }}>
        <Card
          style={{
            width: 'auto',
            height: '300px',
            borderRadius: '10px',
            padding: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'auto',
          }}>
          <Grid justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <Typography variant="h6">Zonas de Entrega:</Typography>
          </Grid>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid>
              <Grid>
                {loadedData?.map((zone) => (
                  <Card
                    style={{
                      backgroundColor: '#FFF',
                      width: '400px',
                      height: '60px',
                      padding: '8px',
                      margin: '10px',
                    }}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Grid style={{ marginRight: '10px' }}>
                        <TwoWheelerIcon style={{ color: '#3e3e3e' }} />
                      </Grid>
                      <Grid style={{ marginRight: '30px' }}>
                        {/* @ts-ignore */}
                        <Typography>Zona: {zone.zoneName}</Typography>
                      </Grid>
                      <Grid style={{ marginRight: '15px' }}>
                        {/* @ts-ignore */}
                        <Typography>Taxa: R$ {cash(zone.zonePrice)}</Typography>
                      </Grid>
                      <Grid style={{ marginLeft: '6px' }}>
                        {/*
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          <EditIcon />
                        </IconButton> */}
                        <IconButton
                          color="secondary"
                          aria-label="deletar area"
                          component="span"
                          onClick={() => {
                            // @ts-ignore
                            handleDeleteArea(zone.id, zone.zoneName, zone.zonePrice);
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <Typography variant="h6">Cadastro de Nova Zona:</Typography>
          </Grid>
          <Grid direction="column" justifyContent="center" alignItems="center">
            <Grid style={{ margin: '30px' }}>
              <TextField
                inputRef={zoneNameRef}
                style={{ width: '360px' }}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwoWheelerIcon style={{ color: '#3e3e3e' }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Nome da Zona"
              />
            </Grid>
            <Grid style={{ margin: '30px' }}>
              <TextField
                inputRef={zonePriceRef}
                style={{ width: '360px' }}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MonetizationOnIcon style={{ color: 'grey' }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Valor da Taxa"
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{ margin: '10px' }}>
            <Grid style={{ margin: '10px' }}>
              <Button
                onClick={() => {
                  handleClose();
                }}
                style={{ width: '160px' }}
                variant="contained"
                color="secondary">
                Cancelar
              </Button>
            </Grid>
            <Grid style={{ margin: '10px' }}>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                style={{ width: '160px' }}
                variant="contained"
                color="primary">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openDelete}
        onClose={() => {
          setOpenDelete(!openDelete);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <Typography variant="h6">Confirmar exclusão da zona ?</Typography>
          </Grid>
          <Grid direction="column" justifyContent="center" alignItems="center">
            <Grid style={{ margin: '30px' }}>
              <TextField
                inputRef={zoneNameRef}
                style={{ width: '360px' }}
                variant="outlined"
                disabled
                // @ts-ignore
                value={zoneToDelete?.zoneName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwoWheelerIcon style={{ color: '#3e3e3e' }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Nome da Zona"
              />
            </Grid>
            <Grid style={{ margin: '30px' }}>
              <TextField
                inputRef={zonePriceRef}
                style={{ width: '360px' }}
                variant="outlined"
                disabled
                // @ts-ignore
                value={`R$ ${zoneToDelete?.zonePrice}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MonetizationOnIcon style={{ color: 'grey' }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Valor da Taxa"
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{ margin: '10px' }}>
            <Grid style={{ margin: '10px' }}>
              <Button
                onClick={() => {
                  setOpenDelete(!openDelete);
                }}
                style={{ width: '160px' }}
                variant="contained"
                color="secondary">
                Cancelar
              </Button>
            </Grid>
            <Grid style={{ margin: '10px' }}>
              <Button
                onClick={() => {
                  handleConfirmDeleteArea();
                }}
                style={{ width: '160px' }}
                variant="contained"
                color="primary">
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <div ref={mapContainer} style={{ width: '100vw', height: '100vh' }} />
    </div>
  );
}
